import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { CurrencyFormatter } from '@x/common/money/currency-formatter';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'USD',
    private formatter: CurrencyFormatter,
  ) {}
  transform(
    cents: number | string | null | undefined,
    currencyCode: string | undefined | null,
    digitsInfo?: string,
  ) {
    if (cents === undefined || cents === null) {
      return null;
    }
    if (typeof cents === 'string') {
      cents = parseInt(cents);
    }

    return this.formatter.format(
      cents / 100,
      this._locale,
      currencyCode ?? this._defaultCurrencyCode,
      digitsInfo,
    );
  }
}
