import { InjectionToken } from '@angular/core';

export const MONEY_FORMAT_CONFIG = new InjectionToken<MoneyFormatConfig>(
  'Default MoneyFormatConfig',
);

export type MoneyFormatConfig = Omit<Intl.NumberFormatOptions, 'style' | 'currency'>;

export class MoneyModuleConfig {
  format: MoneyFormatConfig;
}
