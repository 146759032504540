import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { MenuItemNode } from './menu-item';
import { MenuService } from './menu.service';

@Component({
  selector: 'x-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss'],
  host: {
    class: 'x-menu',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, OnDestroy {
  state$ = this.menuService.observeState();
  tree$ = this.menuService.observeTree();
  pins$ = this.menuService.observePinned();

  private _destroy$ = new Subject<void>();

  constructor(
    public menuService: MenuService,
    private changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  togglePin(node: MenuItemNode, event?: MouseEvent) {
    event?.stopPropagation();
    event?.preventDefault();

    this.menuService.togglePinItem(node);
  }

  hasChild = (_: number, node: MenuItemNode) => node.children.length > 0;
  trackById = (_: number, node: MenuItemNode) => node.id;
}
