import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionVariantService } from '@x/ecommerce/domain-client';
import {
  AgreementCollectionProvider,
  ChannelAutocompleteDataSource,
  ProductVariantAutocompleteDatasource,
} from '@x/ecommerce/domain-data';
import {
  SubscriptionVariantInput,
  SubscriptionVariantTranslationInput,
} from '@x/schemas/ecommerce';
import { Subject, map } from 'rxjs';

export interface SubscriptionVariantDialogData {
  subscriptionId: number;
}

export interface SubscriptionVariantDialogResult {
  data?: SubscriptionVariantInput;
}

@Component({
  selector: 'x-subscription-variant-create-dialog',
  templateUrl: './subscription-variant-dialog.component.html',
  styleUrls: ['./subscription-variant-dialog.component.scss'],
  providers: [ProductVariantAutocompleteDatasource],
})
export class SubscriptionVariantDialogComponent {
  readonly Providers = {
    AgreementCollectionProvider,
  };
  //unsubscribe
  private _destroy$ = new Subject<void>();

  subscriptionVariantId: number;
  verb: string = 'Create';

  subscriptionVariantForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    interval: new UntypedFormControl([], Validators.required),
    duration: new UntypedFormControl(null),
    autoRenew: new UntypedFormControl(false, Validators.required),
    channel: new UntypedFormControl([], Validators.required),
    locale: new UntypedFormControl([], Validators.required),
    agreementIds: new UntypedFormControl([]),
  });

  hasChannelId$ = this.subscriptionVariantForm
    .get('channel')
    ?.valueChanges.pipe(map((channel) => !!channel));

  constructor(
    public dialog: MatDialogRef<SubscriptionVariantDialogComponent>,
    private subscriptionVariantService: SubscriptionVariantService,
    public channelDataSource: ChannelAutocompleteDataSource,
    @Inject(MAT_DIALOG_DATA) public data: SubscriptionVariantDialogData,
  ) {}

  submit() {
    this.subscriptionVariantForm.updateValueAndValidity();

    if (this.subscriptionVariantForm.invalid) return;

    const form = this.subscriptionVariantForm.value;

    const data: SubscriptionVariantInput = {
      name: form.name,
      autoRenew: form.autoRenew,
      channelId: form.channel,
      interval: form.interval,
      subscriptionId: this.data.subscriptionId,
      duration: form.duration,
      translations: [],
      agreementIds: form.agreementIds ?? [],
    };

    const translation: SubscriptionVariantTranslationInput = {
      locale: form.locale,
      name: form.name,
    };

    data.translations?.push(translation);

    this.dialog.close({ data });
    return data;

    // this.subscriptionVariantService
    //   .create(data)
    //   .pipe(
    //     takeUntil(this._destroy$),
    //     tap((data) => this.dialog.close({ data })),
    //   )
    //   .subscribe((data) => {
    //     return data;
    //   });
  }
}
