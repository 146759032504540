import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CurrencyFormatter } from './currency-formatter';
import { CurrencySymbolPipe } from './currency-symbol-pipe.pipe';
import { FromCentsPipe } from './from-cents.pipe';
import { MONEY_FORMAT_CONFIG, MoneyModuleConfig } from './money.config';
import { MoneyPipe } from './money.pipe';

const DECLARATIONS = [CurrencySymbolPipe, FromCentsPipe, MoneyPipe];

@NgModule({
  declarations: DECLARATIONS,
  imports: [CommonModule],
  exports: DECLARATIONS,
})
export class MoneyModule {
  static forRoot(config: MoneyModuleConfig): ModuleWithProviders<MoneyModule> {
    return {
      ngModule: MoneyModule,
      providers: [{ provide: MONEY_FORMAT_CONFIG, useValue: config.format }, CurrencyFormatter],
    };
  }
}
