@if (pins$ | async; as nodes) {
  @for (node of nodes; track node.id) {
    <ng-container
      [ngTemplateOutlet]="childTemplate"
      [ngTemplateOutletContext]="{ $implicit: node }"
    ></ng-container>
  }
}
<mat-divider></mat-divider>
@if (tree$ | async; as nodes) {
  @for (node of nodes; track node.id) {
    <ng-container
      [ngTemplateOutlet]="nodeTemplate"
      [ngTemplateOutletContext]="{ $implicit: node }"
    ></ng-container>
  }
}

<ng-template #nodeTemplate let-node>
  <div class="menu-item-node">
    <div
      class="menu-item parent"
      matRipple
      [class.expanded]="node.expanded"
      [class.item-active]="node.active"
      [class.hidden]="node.hidden"
      (click)="menuService.toggleItem(node)"
    >
      @if (node.icon) {
        <fa-icon class="item-icon" [icon]="node.icon" size="lg"></fa-icon>
      }
      <span class="item-title">{{ node.title }}</span>
      @if (node.children.length) {
        <fa-icon
          class="item-toggle"
          [icon]="node.expanded ? 'chevron-down' : 'chevron-right'"
        ></fa-icon>
      }
    </div>
    @if (node.expanded && node.children.length) {
      <div class="menu-item-children">
        @for (child of node.children; track child.id) {
          <ng-container
            [ngTemplateOutlet]="childTemplate"
            [ngTemplateOutletContext]="{ $implicit: child }"
          ></ng-container>
        }
      </div>
    }
  </div>
</ng-template>

<ng-template #childTemplate let-node>
  <div class="menu-item-node">
    <a
      class="menu-item child"
      matRipple
      [class.expanded]="node.expanded"
      [class.item-active]="node.active"
      [class.hidden]="node.hidden"
      [routerLink]="node.route"
    >
      @if (node.icon) {
        <fa-icon class="item-icon" [icon]="node.icon"></fa-icon>
      }
      <span class="item-title">{{ node.title }}</span>
      <fa-icon
        class="item-star"
        icon="thumbtack"
        [rotate]="node.pinned ? 90 : undefined"
        (click)="togglePin(node, $event)"
      ></fa-icon>
    </a>
    @if (node.children.length) {
      <div class="menu-item-children">
        @for (child of node.children; track child.id) {
          <ng-container
            [ngTemplateOutlet]="childTemplate"
            [ngTemplateOutletContext]="{ $implicit: child }"
          ></ng-container>
        }
      </div>
    }
  </div>
</ng-template>

@if (state$ | async; as state) {
  <div class="menu-settings">
    <button mat-icon-button [matMenuTriggerFor]="settingsMenu">
      <fa-icon icon="cog"></fa-icon>
    </button>
  </div>

  <mat-menu #settingsMenu="matMenu">
    <ng-template matMenuContent>
      <button mat-menu-item (click)="menuService.toggleShowHidden()">
        <fa-icon [icon]="state.showHidden ? 'eye' : 'eye-slash'"></fa-icon>
        <span>Show Hidden Items</span>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="drawerModeMenu">
        <fa-icon icon="sidebar"></fa-icon>
        <span>Drawer Mode</span>
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #drawerModeMenu="matMenu">
    <ng-template matMenuContent>
      <button mat-menu-item (click)="menuService.setDrawerMode('over')">
        @if (state.drawerMode === 'over') {
          <fa-icon icon="check"></fa-icon>
        }
        <span>Overlay</span>
      </button>
      <button mat-menu-item (click)="menuService.setDrawerMode('side')">
        @if (state.drawerMode === 'side') {
          <fa-icon icon="check"></fa-icon>
        }
        <span>Side</span>
      </button>
      <button mat-menu-item (click)="menuService.setDrawerMode('push')">
        @if (state.drawerMode === 'push') {
          <fa-icon icon="check"></fa-icon>
        }
        <span>Push</span>
      </button>
    </ng-template>
  </mat-menu>
}
