import { Inject, Injectable } from '@angular/core';
import { MONEY_FORMAT_CONFIG, MoneyFormatConfig } from '@x/common/money/money.config';

const NUMBER_FORMAT_REGEXP = /^(\d+)?\.((\d+)(-(\d+))?)?$/;

@Injectable()
export class CurrencyFormatter {
  readonly formatters = new Map<string, Intl.NumberFormat>();

  constructor(@Inject(MONEY_FORMAT_CONFIG) private config: MoneyFormatConfig) {}

  getFormatter(locale: string, currency: string, digitsInfo?: string) {
    const id = `${locale}/${currency}${digitsInfo ? `/${digitsInfo}` : ''}`;

    let formatter = this.formatters.get(id);

    if (!formatter) {
      let options: Intl.NumberFormatOptions = {
        style: 'currency',
        currency,
        ...this.config,
        ...(digitsInfo ? this.parseDigitsInfo(digitsInfo) : {}),
      };

      formatter = new Intl.NumberFormat(locale, options);
      this.formatters.set(id, formatter);
    }

    return formatter;
  }

  format(value: number, locale: string, currency: string, digitsInfo?: string) {
    return this.getFormatter(locale, currency, digitsInfo).format(value);
  }

  /**
   * https://github.com/angular/angular/blob/9020a50e6d6f1c6bc6b4cb2ee60c1df6d7a6d7c6/packages/common/src/i18n/format_number.ts#L56
   */
  private parseDigitsInfo(digitsInfo: string) {
    const parts = digitsInfo.match(NUMBER_FORMAT_REGEXP);
    let minimumIntegerDigits = 1,
      minimumFractionDigits = 2,
      maximumFractionDigits = 2;

    if (parts === null) {
      throw new Error(`${digitsInfo} is not a valid digit info`);
    }

    const minimumIntegerDigitsPart = parts[1];
    const minimumFractionDigitsPart = parts[3];
    const maximumFractionDigitsPart = parts[5];
    if (minimumIntegerDigitsPart != null) {
      minimumIntegerDigits = parseInt(minimumIntegerDigitsPart);
    }
    if (minimumFractionDigitsPart != null) {
      minimumFractionDigits = parseInt(minimumFractionDigitsPart);
    }
    if (maximumFractionDigitsPart != null) {
      maximumFractionDigits = parseInt(maximumFractionDigitsPart);
    } else if (minimumFractionDigitsPart != null && minimumFractionDigits > maximumFractionDigits) {
      maximumFractionDigits = minimumFractionDigits;
    }

    return {
      maximumFractionDigits,
      minimumFractionDigits,
      minimumIntegerDigits,
    };
  }
}
